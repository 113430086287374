import { useEffect, useMemo, useRef, useState } from "react";
import { Carousel, Spinner, Typography } from "@material-tailwind/react";
import {
  CartesianGrid,
  Cell,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";
import { FACEMESH_TESSELATION, FACEMESH_CONTOURS } from "@mediapipe/face_mesh";
import { drawConnectors } from "@mediapipe/drawing_utils";
import { io } from "socket.io-client";

import { constants } from "../utils/ranges";

import graphData1 from "../assets/ppg-graph-data/1.json";
import graphData2 from "../assets/ppg-graph-data/2.json";
import graphData3 from "../assets/ppg-graph-data/3.json";
import graphData4 from "../assets/ppg-graph-data/4.json";
import graphData5 from "../assets/ppg-graph-data/5.json";
import graphData6 from "../assets/ppg-graph-data/6.json";
import graphData7 from "../assets/ppg-graph-data/7.json";
import graphData8 from "../assets/ppg-graph-data/8.json";

import BackgroundVideo from "../assets/videos/Background_Video.mp4";
import QRCode from "../assets/logos/QRCode.png";
import { ReactComponent as VendorLogo } from "../assets/logos/VendorLogo.svg";
import { ReactComponent as CarePlixLogo } from "../assets/logos/CarePlix.svg";
import { ReactComponent as FaceMesh } from "../assets/icons/FaceMesh2.svg";
import { ReactComponent as HeartHealthScore } from "../assets/icons/HeartHealthScore.svg";
import { ReactComponent as MentalHealthScore } from "../assets/icons/MentalHealthScore.svg";
import { ReactComponent as PhysiologicalHealthScore } from "../assets/icons/PhysiologicalHealthScore.svg";

import { ReactComponent as CheckFilled } from "../assets/icons/CheckFilled.svg";

const dataSet = [
  graphData1,
  graphData2,
  graphData3,
  graphData4,
  graphData5,
  graphData6,
  graphData7,
  graphData8,
];

const getRawData = () => {
  const rawData = dataSet[Math.floor(Math.random() * dataSet.length)];
  return rawData.x.map((x, i) => ({ x: x - 16000, y: rawData.y[i] ?? 0 }));
};

const VitalItem = ({
  className = "",
  label = "",
  value = 0,
  noRange = false,
}) => (
  <div className={`flex flex-col items-stretch justify-between ${className}`}>
    <Typography variant="h5" className="text-lg font-medium leading-normal">
      {label}
    </Typography>
    <p className="mt-5">
      <span className="text-3xl font-bold">{value}</span>
      <span className="ml-3 lg:text-base md:text-[12px] opacity-60">
        {constants[label].unit}
      </span>
    </p>
    {!noRange && (
      <p className="mt-1 lg:text-base md:text-[12px] tracking-widest uppercase">
        {
          constants[label].ranges.reduce(
            (p, c) => (value >= c.point ? c : p),
            0
          ).label
        }
      </p>
    )}
  </div>
);

const ScoreItem = ({ dir = "", className = "", label = "", Icon, value }) => (
  <div
    dir={dir}
    className={`lg:w-60 md:w-[100%] p-6 flex lg:flex-col md:flex-row lg:items-start md:items-center justify-between gap-4 rounded-3xl border-2 border-primary bg-primary/40 ${className}`}
  >
    <div className="flex items-center justify-between gap-3">
      <Icon className="shrink-0 h-10 w-10 text-white" />
      <Typography variant="h5" className="text-lg font-medium leading-normal">
        {label}
      </Typography>
    </div>
    <div>
      <p className="text-3xl font-bold">
        {value}/<span className="opacity-70">100</span>
      </p>
      <p className="mt-1 text-base tracking-widest uppercase">
        {
          constants["Score"].ranges.reduce((p, c) => (value > c.point ? c : p))
            .label
        }
      </p>
    </div>
  </div>
);

const Visualize = () => {
  const { t, i18n } = useTranslation("LargeScreen");
  const audioRef = useRef();
  const canvasRef = useRef();
  const [userdata, setUserdata] = useState({
    age: "",
    dob: "",
    gender: "",
    height: "",
    weight: "",
  });
  const [error, setError] = useState("");
  const [analyzing, setAnalyzing] = useState(false);
  const [analyzingStep, setAnalyzingStep] = useState(0);
  const [facePoints, setFacePoints] = useState(0);
  const [toneMap, setToneMap] = useState([]);
  const [frameData, setFrameData] = useState({
    type: "",
    timeElapsed: 0,
    confidence: 0,
    fps: 0,
  });
  const [result, setResult] = useState(undefined);

  const [graphData, setGraphData] = useState([]);

  const analyzingMsgDuration = 1000;
  const analyzingMsgList = useMemo(
    () => [
      t("analyzing_message_0"),
      t("analyzing_message_1"),
      t("analyzing_message_2"),
      t("analyzing_message_3"),
      t("analyzing_message_4"),
      t("analyzing_message_5"),
    ],
    [t]
  );

  const tonesBinary = useMemo(() => {
    if (toneMap?.length < 4) {
      const zeros = ("0".repeat(8) + " ").repeat(4);
      return `${zeros}\n${zeros}\n${zeros}`;
    }
    const lineR = [];
    const lineG = [];
    const lineB = [];
    toneMap.slice(-4).forEach((color) => {
      lineR.push(((color.r ?? 0) >>> 0).toString(2).padStart(8, "0"));
      lineG.push(((color.g ?? 0) >>> 0).toString(2).padStart(8, "0"));
      lineB.push(((color.b ?? 0) >>> 0).toString(2).padStart(8, "0"));
    });
    return `${lineR.join(" ")}\n${lineG.join(" ")}\n${lineB.join(" ")}`;
  }, [toneMap]);

  useEffect(() => {
    let timer;
    if (analyzing) {
      if (analyzingStep < analyzingMsgList.length - 1) {
        timer = setTimeout(() => {
          setAnalyzingStep((s) => s + 1);
        }, analyzingMsgDuration);
      }
    } else setAnalyzingStep(0);
    return () => {
      clearTimeout(timer);
    };
  }, [analyzing, analyzingMsgList.length, analyzingStep]);

  const reset = () => {
    setUserdata({ age: "", dob: "", gender: "", height: "", weight: "" });
    setFacePoints(0);
    setToneMap([]);
    setError("");
    setAnalyzing(false);
    setAnalyzingStep(0);
    setFrameData({ type: "", timeElapsed: 0, confidence: 0, fps: 0 });
    setResult(undefined);
    setGraphData([]);
  };

  useEffect(() => {
    const playAudio = (fileName = "") => {
      if (fileName?.length > 0) {
        audioRef.current?.pause?.();
        audioRef.current = new Audio(
          `${process.env.PUBLIC_URL}/audios/${i18n.resolvedLanguage}/${fileName}.mp3`
        );
        audioRef.current
          ?.play?.()
          .then(() => {
            console.log(`Finished playing ${fileName}.mp3`);
          })
          .catch((err) => {
            console.log(`Failed to play ${fileName}.mp3`);
            console.error(err);
          });
      } else console.error("Provide filename to play audio");
    };
    let selectedGraphData = [];
    const socket = io();
    socket.on("reset", () => {
      reset();
    });
    socket.on("changeLanguageTo", (lang) => {
      i18n.changeLanguage(lang, () => {
        document.body.dir = i18n.dir();
      });
    });
    socket.on("playAudio", (key) => {
      playAudio(key);
    });
    socket.on("startscan", ({ resolution, userdata }) => {
      if (canvasRef.current) {
        canvasRef.current.width = resolution?.width ?? 0;
        canvasRef.current.height = resolution?.height ?? 0;
      }
      reset();
      const ageDifMs = Date.now() - new Date(userdata.dob).getTime();
      const ageDate = new Date(ageDifMs);
      const age = Math.abs(ageDate.getUTCFullYear() - 1970).toFixed(0);
      setUserdata({ ...userdata, age });
      selectedGraphData = getRawData();
      setGraphData(
        Array.from({ length: 200 }, (_, i) => ({ x: 20 * i, y: 0 }))
      );
    });
    socket.on("framedata", (data) => {
      const { landmarksList, tone, ...framedata } = data;
      setFrameData(framedata);
      if (typeof tone !== "undefined")
        setToneMap((t) => [
          ...t,
          { ...tone, timestamp: framedata.timeElapsed },
        ]);
      setFacePoints(
        landmarksList?.reduce?.((sum, landmark) => {
          if (landmark.x < 0 || landmark.x > 1) return sum;
          else if (landmark.y < 0 || landmark.y > 1) return sum;
          else return sum + 1;
        }, 0) ?? 0
      );
      const ctx = canvasRef.current?.getContext?.("2d");
      if (typeof ctx !== "undefined") {
        const frameWidth = canvasRef.current.width;
        const frameHeight = canvasRef.current.height;
        const lineSize = Math.round(((frameWidth + frameHeight) / 2) * 0.1);
        ctx.save();
        ctx.clearRect(0, 0, frameWidth, frameHeight);
        ctx.beginPath();
        ctx.moveTo(frameWidth - lineSize, 0);
        ctx.lineTo(frameWidth, 0);
        ctx.lineTo(frameWidth, lineSize);
        ctx.moveTo(lineSize, frameHeight);
        ctx.lineTo(0, frameHeight);
        ctx.lineTo(0, frameHeight - lineSize);
        ctx.lineWidth = 3;
        ctx.strokeStyle = "#3298f1";
        ctx.stroke();
        ctx.restore();
        if (landmarksList?.length > 0) {
          drawConnectors(ctx, landmarksList, FACEMESH_TESSELATION, {
            color: "#ccc5",
            lineWidth: 1,
          });
          drawConnectors(ctx, landmarksList, FACEMESH_CONTOURS, {
            color: "#ccc",
            lineWidth: 1,
          });
        } else {
          ctx.fillStyle = "#fff0";
          ctx.fillRect(0, 0, frameWidth, frameHeight);
        }
        ctx.restore();
        if (framedata.type === "scan") {
          if (landmarksList?.length > 0)
            setGraphData((data) => {
              data.shift();
              return [...data, selectedGraphData.shift()];
            });
          else
            setGraphData((data) => {
              const { x: last2X } = data[data.length - 2];
              const { x: lastX } = data[data.length - 1];
              data.shift();
              selectedGraphData.shift();
              return [...data, { x: lastX + (lastX - last2X), y: 0 }];
            });
        }
      }
    });
    socket.on("analyzing", () => {
      setGraphData([]);
      setAnalyzing(true);
    });
    socket.on("scanresult", (res) => {
      setResult(res);
    });
    socket.on("error", (msg) => {
      const ctx = canvasRef.current?.getContext?.("2d");
      if (typeof ctx !== "undefined") {
        const frameWidth = canvasRef.current.width;
        const frameHeight = canvasRef.current.height;
        ctx.save();
        ctx.fillStyle = "#fff0";
        ctx.fillRect(0, 0, frameWidth, frameHeight);
        ctx.clearRect(0, 0, frameWidth, frameHeight);
        ctx.restore();
      }
      setGraphData([]);
      setError(msg);
    });
    return () => {
      socket.disconnect();
    };
  }, [i18n]);

  return (
    <main className="h-screen overflow-hidden flex flex-col items-stretch justify-start text-white">
      <div className="fixed inset-0 bg-primary -z-50">
        <video
          className="h-full w-full object-cover object-center"
          src={BackgroundVideo}
          loop
          autoPlay
          muted
          playsInline
          disablePictureInPicture
          disableRemotePlayback
        />
      </div>
      <VendorLogo className="shrink-0 h-12 my-6 mx-10 mr-auto" />

      <section
        dir="ltr"
        style={{
          display:
            error.length > 0 ||
            result?.scan_id?.length > 0 ||
            frameData.type.length > 0
              ? "none"
              : "block",
        }}
        className="grow relative px-[7.5%]"
      >
        <div className="absolute inset-x-[7.5%] inset-y-0 -z-10 opacity-90">
          <div className="animated-border-box-glow" />
          <div className="animated-border-box" />
        </div>
        <div className="h-full flex md:flex-col lg:flex-row items-center lg:justify-between md:justify-center">
          <div className="p-[2vw] lg:w-[70%] md:w-[100%] text-center lg:order-1 md:order-2">
            <h1 className="text-[2.1vw] leading-[4.3vw] font-medium">
              Experience the New Era of Health Monitoring
              <br />
              Witness Digital Heath Assement with Careplix
            </h1>
            <div className="divider my-[1.5vw] mx-auto h-1 w-[70%]" />
            <h3 className="text-white/80 text-[2.1vw] leading-[4.2vw] font-light">
              AI-Powered Face Scan for Vital Insights
              <br />
              CDSCO Registered Class B Medical Device
            </h3>
            <p className="mt-[3vw] text-[1.5vw] font-light">
              Click on&nbsp;
              <span className="text-white font-semibold">Get Started</span>
              &nbsp;on the screen ahead&nbsp;&gt;
            </p>
          </div>
          <FaceMesh className="shrink-0 lg:w-[27%] md:w-[100%] lg:order-2 md:order-1 text-white/75" />
        </div>
      </section>

      <section
        dir="ltr"
        className="lg:grow md:grow-0 lg:flex-row md:flex-col lg:h-auto md:h-[100vh] lg:px-[7.5%] md:px-[5%] items-stretch lg:justify-between md:justify-center"
        style={{
          display:
            !(result?.scan_id?.length > 0) && frameData.type.length > 0
              ? "flex"
              : "none",
        }}
      >
        <div className="basis-[60%] lg:h-auto md:h-[50vh] relative">
          <div className="absolute inset-0 -z-10 opacity-90">
            <div className="animated-border-box-glow" />
            <div className="animated-border-box" />
          </div>
          <div
            style={{ display: error.length > 0 || analyzing ? "none" : "flex" }}
            className="h-full items-stretch justify-between p-[2vw]"
          >
            <div className="lg:basis-[55%] md:basis-[55%] h-full relative">
              <canvas
                height={640}
                width={360}
                ref={canvasRef}
                className="absolute h-full inset-y-0 left-1/2 -translate-x-1/2 -scale-x-100"
              />
              {facePoints > 0 ? (
                <div class="scan-animation divider w-3/4" />
              ) : (
                <p
                  dir={i18n.dir()}
                  className="absolute top-1/2 -translate-y-1/2 inset-x-0 text-white/80 text-center lg:text-[0.7vw] md:text-sm leading-[1.5vw] lg:tracking-[3px] md:tracking-[1px]"
                >
                  {t("faceNotDetected")}
                </p>
              )}
            </div>
            <div className="lg:basis-[40%] md:basis-[40%] flex flex-col items-stretch">
              <div className="shrink-0 grow-0 lg:basis-[30%] md:basis-[100px] relative">
                <ResponsiveContainer
                  className="shrink-0 lg:h-auto md:h-[100px]"
                  width="100%"
                  height="100%"
                >
                  <LineChart
                    data={toneMap}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid
                      strokeDasharray="4 2"
                      stroke="#fff"
                      strokeWidth={1}
                      strokeOpacity={0.5}
                    />
                    <XAxis
                      hide
                      dataKey="timestamp"
                      type="number"
                      domain={[10000, 70000]}
                      tickCount={11}
                      interval={0}
                    />
                    <YAxis
                      hide
                      type="number"
                      domain={
                        frameData.type === "scan"
                          ? ["dataMin", "dataMax"]
                          : [0, 255]
                      }
                      tickCount={21}
                      interval={0}
                    />
                    <Line type="linear" dataKey="r" dot={false} stroke="#f00" />
                    <Line type="linear" dataKey="g" dot={false} stroke="#0f0" />
                    <Line type="linear" dataKey="b" dot={false} stroke="#00f" />
                  </LineChart>
                </ResponsiveContainer>
                {frameData.type !== "scan" && (
                  <p
                    dir={i18n.dir()}
                    className="absolute top-1/2 -translate-y-1/2 inset-x-0 text-center lg:text-[1.3vw] md:text-sm text-white lg:tracking-[10px] md:tracking-[3px]"
                  >
                    {t("initializing")}
                  </p>
                )}
              </div>
              <p className="my-[1vw] text-white/80 lg:text-[0.7vw] md:text-sm lg:leading-[1.5vw] md:leading-[18px] lg:tracking-[2.5px] md:tracking-[1.5px] font-light font-['ui-monospace'] whitespace-pre-line">
                {tonesBinary}
              </p>
              <div className="lg:mt-[2vw] md:mt-[10px] flex flex-col items-stretch gap-[0.75vw] text-white/80 lg:text-[0.7vw] md:text-sm lg:tracking-[3px] md:tracking-[1px] uppercase">
                <div className="grid grid-cols-2 gap-x-6 gap-y-[0.75vw]">
                  <p>GENDER&nbsp;:&nbsp;{userdata.gender}</p>
                  <p>AGE&nbsp;:&nbsp;{userdata.age}</p>
                  <p>HEIGHT&nbsp;:&nbsp;{userdata.height}</p>
                  <p>WEIGHT&nbsp;:&nbsp;{userdata.weight}</p>
                </div>
                <p>
                  TIME&nbsp;:&nbsp;{Math.round(frameData.timeElapsed / 1000)}
                  &nbsp;SECS
                </p>
                <p>
                  FACE&nbsp;:&nbsp;
                  {facePoints > 0 ? "DETECTED" : "CANNOT DETECT"}
                </p>
                <p>DETECTION&nbsp;:&nbsp;{facePoints}&nbsp;POINTS</p>
                <p>R&nbsp;:&nbsp;{Math.round(toneMap.at(-1)?.r ?? 0)}</p>
                <p>G&nbsp;:&nbsp;{Math.round(toneMap.at(-1)?.g ?? 0)}</p>
                <p>B&nbsp;:&nbsp;{Math.round(toneMap.at(-1)?.b ?? 0)}</p>
                <p>
                  DATA QUALITY&nbsp;:&nbsp;
                  {frameData.fps > 15
                    ? frameData.fps > 20
                      ? "Good"
                      : "Moderate"
                    : "Poor"}
                </p>
              </div>
            </div>
          </div>
          {error.length > 0 ? (
            <p className="absolute top-1/2 -translate-y-1/2 inset-x-0 text-white/80 text-center lg:text-[0.7vw] md:text-sm leading-[1.5vw] tracking-[3px]">
              NO DATA AVAILABLE
            </p>
          ) : (
            analyzing && (
              <div
                dir={i18n.dir()}
                className="h-full py-[2vw] px-[15%] flex flex-col items-stretch justify-evenly gap-8"
              >
                {analyzingMsgList.map((msg, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between gap-12"
                  >
                    <p className="lg:text-[1.3vw] md:text-sm lg:tracking-[7px] sm:tracking-[3px]">
                      {msg}
                    </p>
                    {analyzingStep === index ? (
                      <Spinner
                        className="shrink-0 lg:h-8 sm:h-6 lg:w-8 sm:w-6"
                        color="white"
                      />
                    ) : (
                      analyzingStep > index && (
                        <CheckFilled className="shrink-0 lg:h-8 sm:h-6 lg:w-8 sm:w-6 text-primary" />
                      )
                    )}
                  </div>
                ))}
              </div>
            )
          )}
        </div>
        <aside className="basis-[35%] lg:h-auto md:h-[40vh] relative">
          <div className="absolute inset-0 -z-10 opacity-90">
            <div className="animated-border-box-glow" />
            <div className="animated-border-box" />
          </div>
          <div className="h-full lg:py-[2vw] md:py-[20px] flex flex-col items-stretch">
            <div className="shrink-0 grow-0 lg:basis-[35%] md:basis-[35%] lg:px-[2vw] md:px-[30px] relative">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={graphData}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <CartesianGrid
                    strokeDasharray="4 2"
                    stroke="#fff"
                    strokeWidth={1}
                    strokeOpacity={0.5}
                  />
                  <XAxis
                    hide
                    dataKey="x"
                    type="number"
                    domain={["dataMin", "dataMax"]}
                    tickCount={25}
                    interval={0}
                  />
                  <YAxis
                    hide
                    dataKey="y"
                    type="number"
                    domain={[-0.1, 0.1]}
                    tickCount={15}
                    interval={0}
                  />
                  <Line
                    type="monotone"
                    dataKey="y"
                    dot={false}
                    stroke="#3298F1"
                  />
                </LineChart>
              </ResponsiveContainer>
              {(graphData.length === 0 || frameData.type !== "scan") && (
                <p
                  dir={i18n.dir()}
                  className="absolute top-1/2 -translate-y-1/2 inset-x-0 text-center lg:text-[1.3vw] sm:text-sm text-white lg:tracking-[10px] sm:tracking-[3px]"
                >
                  {error.length > 0
                    ? "-------"
                    : analyzing
                    ? t("analyzing")
                    : t("initializing")}
                </p>
              )}
            </div>
            <div className="divider lg:h-1 md:h-[2px] lg:w-[70%] md:w-full mx-auto lg:my-[2vw] md:my-[15px]" />
            <div
              dir={i18n.dir()}
              className="grow flex flex-col items-stretch justify-around text-center"
            >
              <h4 className="lg:text-[1.3vw] md:text-sm lg:tracking-[10px] md:tracking-[3px]">
                {t("status")}
              </h4>
              <h3 className="shrink-0 md:w-[98%] lg:w-[98%] mx-auto lg:py-3 md:py-1 lg:px-6 md:px-2 bg-primary lg:text-[1.3vw] md:text-sm lg:tracking-[10px] md:tracking-[3px]">
                {error.length > 0
                  ? t("failed")
                  : analyzing
                  ? t("finished")
                  : frameData.type === "scan"
                  ? t("scanning")
                  : t("calibrating")}
              </h3>
              <p>
                {error.length <= 0 && (
                  <>
                    <span className="text-[2.7vw] font-bold">
                      {frameData.type === "scan"
                        ? Math.round((frameData.timeElapsed - 10000) / 600) +
                          "%"
                        : Math.ceil((10000 - frameData.timeElapsed) / 1000)}
                    </span>
                    {frameData.type === "calibration" && (
                      <span className="ml-4 text-[0.85vw] text-white/80 tracking-[10px]">
                        {t("seconds")}
                      </span>
                    )}
                    <br />
                  </>
                )}
                <span className="lg:text-[1.3vw] sm:text-sm text-white/80 lg:tracking-[10px] sm:tracking-[3px]">
                  {error.length > 0
                    ? t("error")
                    : frameData.type === "scan"
                    ? t("completed")
                    : t("start")}
                </span>
              </p>
            </div>
          </div>
        </aside>
      </section>

      <section
        dir="ltr"
        style={{
          display:
            error.length > 0 || !(result?.scan_id?.length > 0)
              ? "none"
              : "block",
        }}
        className="grow relative"
      >
        <div className="absolute inset-x-[7.5%] inset-y-0 -z-10 opacity-90">
          <div className="animated-border-box-glow" />
          <div className="animated-border-box" />
        </div>
        <div className="absolute inset-x-[7.5%] inset-y-0">
          <Carousel
            prevArrow={() => <></>}
            nextArrow={() => <></>}
            navigation={({ activeIndex, length }) => (
              <div className="absolute bottom-6 inset-x-0 z-20 flex items-stretch justify-center gap-6">
                {[...Array(length).keys()].map((i) => (
                  <span
                    key={`carousel-nav-${i}`}
                    className={`shrink-0 h-2 w-2 lg:h-4 lg:w-4 rounded-full ${
                      activeIndex === i ? "bg-secondary" : "bg-white"
                    }`}
                  />
                ))}
              </div>
            )}
            autoplay
            autoplayDelay={5000}
            loop
            className="items-stretch h-full"
          >
            <div className="relative h-full w-full flex flex-col items-center px-[5%] pt-10 pb-16">
              <Typography
                dir={i18n.dir()}
                variant="h1"
                className="mb-6 text-3xl text-center"
              >
                {t("DigitalHealthReport")}
              </Typography>
              <div className="my-auto">
                <div
                  dir={i18n.dir()}
                  className="flex items-center justify-center gap-6"
                >
                  <Typography
                    variant="h5"
                    className="text-center text-2xl font-semibold leading-normal"
                  >
                    {t("OverallHealthScore")}
                  </Typography>
                  <div
                    dir="ltr"
                    className="p-1.5 bg-primary/[0.35] rounded-full relative"
                  >
                    <PieChart
                      width={100}
                      height={100}
                      margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
                      className="bg-primary/50 rounded-full"
                    >
                      <Pie
                        data={[
                          { value: result?.wellness_score ?? 0 },
                          { value: 100 - (result?.wellness_score ?? 0) },
                        ]}
                        dataKey="value"
                        startAngle={270}
                        endAngle={-90}
                        innerRadius="90%"
                        outerRadius="100%"
                        cornerRadius="100%"
                      >
                        <Cell fill="#fff" stroke="none" />
                        <Cell fill="transparent" stroke="none" />
                      </Pie>
                    </PieChart>
                    <p className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 font-bold">
                      <span className="text-2xl">{result?.wellness_score}</span>
                      <span className="text-xl">%</span>
                    </p>
                  </div>
                </div>
                <div className="lg:mt-8 md:mt-10 flex lg:flex-row md:flex-col items-stretch md:relative justify-between gap-4">
                  <div className="lg:relative md:absolute lg:left-auto md:left-[-15%] lg:top-auto md:top-[15%] lg:w-8 md:w-20 -rotate-90">
                    <p
                      dir={i18n.dir()}
                      className="vertical-lined-text w-32 text-primary"
                    >
                      <span className="text-white tracking-[3px] uppercase">
                        {t("Mind")}
                      </span>
                    </p>
                  </div>
                  <ScoreItem
                    dir={i18n.dir()}
                    className="grow"
                    label={t("MentalHealthScore")}
                    value={Math.round(
                      result?.metadata?.mental_wellbeing_score ?? 0
                    )}
                    Icon={MentalHealthScore}
                  />
                  <div className="lg:relative lg:left-auto lg:top-auto md:left-[-15%] md:top-[65%] md:absolute lg:w-8 md:w-20  -rotate-90">
                    <p
                      dir={i18n.dir()}
                      className="vertical-lined-text w-32 text-primary"
                    >
                      <span className="text-white tracking-[3px] uppercase">
                        {t("Body")}
                      </span>
                    </p>
                  </div>
                  <ScoreItem
                    dir={i18n.dir()}
                    label={t("HeartHealthScore")}
                    value={Math.round(
                      result?.metadata?.overall_heart_score ?? 0
                    )}
                    Icon={HeartHealthScore}
                  />
                  <ScoreItem
                    dir={i18n.dir()}
                    label={t("PhysiologicalHealthScore")}
                    value={Math.round(
                      result?.metadata?.physiological_score ?? 0
                    )}
                    Icon={PhysiologicalHealthScore}
                  />
                </div>
              </div>
            </div>
            <div className="relative h-full w-full flex flex-col items-center lg:px-[5%] md:px-[7%] pt-10 pb-16">
              <Typography
                dir={i18n.dir()}
                variant="h1"
                className="mb-6 text-3xl text-center"
              >
                {t("KeyBodyVitals")}
              </Typography>
              <div className="my-auto grid grid-cols-2">
                <VitalItem
                  className="pb-6 pr-6 border-b border-r border-primary/40"
                  label="Heart Rate"
                  value={result?.vitals.heart_rate}
                />
                <VitalItem
                  className="pb-6 pl-6 border-b border-primary/40"
                  label="Respiration Rate"
                  value={result?.vitals.resp_rate}
                />
                <VitalItem
                  className="pt-6 pr-6 border-r border-primary/40"
                  label="Oxygen Saturation"
                  value={result?.vitals.oxy_sat_prcnt}
                />
                <div className="flex flex-col items-stretch justify-between pt-6 pl-6">
                  <Typography
                    variant="h5"
                    className="text-lg font-medium leading-normal"
                  >
                    Blood Pressure
                  </Typography>
                  <div className="flex justify-between gap-16">
                    <div className="mt-5">
                      <p className="text-white/80 text-base">Systolic</p>
                      <p className="mt-1">
                        <span className="text-3xl font-bold">
                          {result?.vitals.bp_sys}
                        </span>
                        <span className="ml-3 lg:text-base md:text-[12px] opacity-60">
                          {constants["Blood Pressure"].unit}
                        </span>
                      </p>
                      <p className="mt-1 lg:text-base md:text-[12px] tracking-widest uppercase">
                        {
                          constants["Blood Pressure"].ranges[0].reduce(
                            (p, c) => (result?.vitals.bp_sys > c.point ? c : p),
                            0
                          ).label
                        }
                      </p>
                    </div>
                    <div className="mt-5">
                      <p className="text-white/80 text-base">Diastolic</p>
                      <p className="mt-1">
                        <span className="text-3xl font-bold">
                          {result?.vitals.bp_dia}
                        </span>
                        <span className="ml-3 lg:text-base md:text-[12px] opacity-60">
                          {constants["Blood Pressure"].unit}
                        </span>
                      </p>
                      <p className="mt-1 lg:text-base md:text-[12px]  tracking-widest uppercase">
                        {
                          constants["Blood Pressure"].ranges[1].reduce(
                            (p, c) => (result?.vitals.bp_dia > c.point ? c : p),
                            0
                          ).label
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative h-full w-full flex flex-col items-center px-[5%] pt-10 pb-16">
              <Typography
                dir={i18n.dir()}
                variant="h1"
                className="mb-6 text-3xl text-center"
              >
                {t("HeartHealth")}
              </Typography>
              <div className="my-auto grid lg:grid-cols-5 md:grid-cols-3">
                <VitalItem
                  className="pb-6 lg:pl-0 md:pl-6 pr-6 border-b border-r border-primary/40"
                  label="SDNN"
                  value={Math.round(result?.metadata.heart_scores.sdnn ?? 0)}
                />
                <VitalItem
                  className="pb-6 px-6 border-b border-r border-primary/40"
                  label="RMSSD"
                  value={Math.round(result?.metadata.heart_scores.rmssd ?? 0)}
                />
                <VitalItem
                  className="pb-6 px-6 border-b lg:border-r md:border-r-0 border-primary/40"
                  label="PNN50"
                  value={result?.metadata.heart_scores.pNN50_per}
                />
                <VitalItem
                  className="pb-6 lg:pt-0 md:pt-6 px-6 border-b border-r border-primary/40"
                  label="Cardiac Output"
                  value={result?.metadata.cardiovascular.cardiac_out}
                />
                <VitalItem
                  className="pb-6 lg:pt-0 md:pt-6 pl-6 border-b lg:border-r-0 md:border-r border-primary/40"
                  label="Mean Arterial Pressure"
                  value={result?.metadata.cardiovascular.map}
                />
                <VitalItem
                  className="pt-6 pr-6 lg:pl-0 md:pl-6 lg:border-r md:border-r-0 lg:border-b-0 md:border-b border-primary/40"
                  label="Heart Utilized"
                  value={result?.metadata.heart_scores.heart_utilized}
                />
                <VitalItem
                  className="pt-6 px-6 border-r lg:border-b-0 md:border-b lg:pb-0 md:pb-6 border-primary/40"
                  label="Heart Rate Max"
                  value={result?.metadata.heart_scores.HRMax}
                  noRange
                />
                <VitalItem
                  className="pt-6 px-6 border-r lg:border-b-0 md:border-b lg:pb-0 md:pb-6 border-primary/40"
                  label="Heart Rate Reserve"
                  value={result?.metadata.heart_scores.HRR}
                  noRange
                />
                <VitalItem
                  className="pt-6 px-6 lg:border-r md:border-r-0 lg:border-b-0 md:border-b lg:pb-0 md:pb-6 border-primary/40"
                  label="Target HR Range"
                  value={result?.metadata.heart_scores.THRR}
                  noRange
                />
                <VitalItem
                  className="pt-6 pl-6 lg:col-auto md:col-start-2"
                  label="VO₂ Max"
                  value={result?.metadata.physiological_scores.vo2max}
                  noRange
                />
              </div>
            </div>
            <div className="relative h-full w-full flex flex-col items-center px-[5%] pt-10 pb-16">
              <Typography
                dir={i18n.dir()}
                variant="h1"
                className="mb-6 text-3xl text-center"
              >
                {t("Physiological")}
              </Typography>
              <div className="my-auto grid grid-cols-3">
                <div className="flex flex-col items-stretch justify-between pb-6 pr-6 border-b border-r border-primary/40">
                  <Typography
                    variant="h5"
                    className="text-lg font-medium leading-normal"
                  >
                    BMI
                  </Typography>
                  <p className="mt-5">
                    <span className="text-3xl font-bold">
                      {result?.metadata.physiological_scores.bmi}
                    </span>
                    <span className="ml-3 text-base opacity-60">
                      {constants["BMI"].unit}
                    </span>
                  </p>
                  <p className="mt-1 text-base tracking-widest uppercase">
                    {
                      constants["BMI"].ranges.reduce(
                        (p, c) =>
                          result?.metadata.physiological_scores.bmi > c.point
                            ? c
                            : p,
                        0
                      ).label
                    }
                  </p>
                </div>
                <VitalItem
                  className="!justify-start pb-6 px-6 border-b border-r border-primary/40"
                  label="Blood Volume"
                  value={result?.metadata.physiological_scores.bloodvolume}
                  noRange
                />
                <VitalItem
                  className="!justify-start pb-6 pl-6 border-b border-primary/40"
                  label="Total Body Water"
                  value={result?.metadata.physiological_scores.tbw}
                  noRange
                />
                <VitalItem
                  className="pt-6 pr-6 border-r border-primary/40"
                  label="Body Water %"
                  value={result?.metadata.physiological_scores.tbwp}
                  noRange
                />
                <VitalItem
                  className="pt-6 px-6 border-r border-primary/40"
                  label="Total Body Fat"
                  value={result?.metadata.physiological_scores.bodyfat}
                  noRange
                />
                <VitalItem
                  className="pt-6 pl-6"
                  label="Body Weight"
                  value={result?.metadata.physiological_scores.weight}
                  noRange
                />
              </div>
            </div>
            <div className="relative h-full w-full flex flex-col items-center px-[5%] pt-10 pb-16">
              <Typography
                dir={i18n.dir()}
                variant="h1"
                className="mb-6 text-3xl text-center"
              >
                {t("StressANS")}
              </Typography>
              {(() => {
                const stressLevel = result?.metadata.heart_scores.stress_index;
                const selectedRangeIndex = constants[
                  "Stress Level"
                ].ranges.reduce(
                  (p, c, ci) => (stressLevel > c.point ? ci : p),
                  0
                );
                const selectedRange =
                  constants["Stress Level"].ranges[selectedRangeIndex];
                const { Icon } = selectedRange;
                let detailTitle = "";
                let detailDescription = "";
                if ((result?.metadata?.heart_scores?.rmssd ?? "--") !== "--") {
                  if (result?.metadata.heart_scores.rmssd < 65) {
                    if (
                      result?.vitals.heart_rate > 100 ||
                      result?.vitals.bp_sys > 130 ||
                      result?.vitals.bp_dia > 90
                    ) {
                      detailTitle = "More Sympathetic Interactions";
                      detailDescription =
                        "Although HRV manifests as a function of your heart rate, it actually originates from your nervous system. Your autonomic nervous system, which controls the involuntary aspects of your physiology, has two branches, parasympathetic (deactivating) and sympathetic (activating).\n\nThe sympathetic nervous system (often called “fight or flight”) reflects responses to things like stress and exercise, and increases your heart rate and blood pressure.";
                    } else {
                      detailTitle = "More Parasympathetic Interactions";
                      detailDescription =
                        "Although HRV manifests as a function of your heart rate, it actually originates from your nervous system. Your autonomic nervous system, which controls the involuntary aspects of your physiology, has two branches, parasympathetic (deactivating) and sympathetic (activating)\n\nThe parasympathetic nervous system (often referred to as “rest and digest”) handles inputs from internal organs, like digestion or your fingernails and hair growing.";
                    }
                  } else {
                    detailTitle = "Balanced ANS Interactions";
                    detailDescription =
                      "When you have high heart rate variability, it means that your body is responsive to both sets of inputs (parasympathetic and sympathetic). This is a sign that your nervous system is balanced, and that your body is very capable of adapting to its environment and performing at its best.";
                  }
                } else {
                  detailDescription =
                    "Not enought data to process. This happens when the signal strength of the scan is poor due to improper light or too much movements.";
                }
                return (
                  <div className="grow flex lg:flex-row md:flex-col items-stretch justify-between gap-6">
                    <div className="shrink-0 basis-[35.5%] lg:w-auto md:w-[80%] lg:mx-0 md:mx-auto p-[2vw] rounded-3xl bg-[#f3f3f3] flex flex-col justify-evenly items-center text-center">
                      <h5 className="text-3xl text-primary font-semibold">
                        {result?.metadata.heart_scores.stress_index}
                      </h5>
                      <h4 className="mt-2 text-base text-primary/80 font-medium">
                        Stress Index (SI)
                      </h4>
                      <Icon className="mt-4 shrink-0 h-16 w-16 text-primary" />
                      <p className="mt-2 text-primary/80 text-base uppercase tracking-widest">
                        {selectedRange.label}
                      </p>
                      <h3 className="mt-4 text-xl text-primary font-semibold">
                        Your Stress Level is {selectedRange.label}
                      </h3>
                      <p className="mt-2 text-primary/80">
                        {constants["Stress Level"].info}
                      </p>
                    </div>
                    <div className="shrink-0 basis-[60%] md:text-center lg:text-start lg:pt-0 md:pt-6">
                      {detailTitle.length > 0 && (
                        <h3 className="mb-6 text-2xl font-semibold">
                          {detailTitle}
                        </h3>
                      )}
                      <p className="text-white/80 text-base lg:text-justify md:text-center leading-normal">
                        {detailDescription}
                      </p>
                      <h5 className="inline-block mt-6 px-8 py-4 rounded-xl bg-primary/20 text-base text-center font-semibold">
                        Autonomic Nervous Systems (ANS)
                      </h5>
                    </div>
                  </div>
                );
              })()}
            </div>
          </Carousel>
        </div>
      </section>

      <div
        dir="ltr"
        className="shrink-0 my-4 mx-6 lg:mx-10 flex items-end justify-between lg:gap-12 md:gap-6"
      >
        <div className="relative">
          <img
            className="absolute -top-2 -translate-y-full left-1/2 -translate-x-1/2 h-24 w-24 lg:h-32 lg:w-32 z-50"
            src={QRCode}
            alt="QRCode"
          />
          <p className="text-center text-sm leading-snug font-bold">
            Join Us in the Journey
            <br />
            to Better Health!
          </p>
        </div>
        <CarePlixLogo className="shrink-0 h-20" />
      </div>
    </main>
  );
};

export default Visualize;
